import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-costs-datatable"
export default class extends Controller {
  static targets = [ "datatable", "skeletonTableBody" ]

  connect() {
    const dataTableElement = this.datatableTarget
    const skeletonTableBodyHTML = this.skeletonTableBodyTarget.innerHTML

    const customCostsTable = $(dataTableElement).DataTable(
          {
              "columnDefs": [
                  {
                      "targets": 0,
                      "searchable": false,
                      "orderable": false,
                      "data": "status",
                      "render": function ( data, type, row, meta ) {
                        let status = row.status
                        let id = row.id
                        let status_text = status ? 'Active' : 'Stopped'
                        let customCostStatusBtnsHTML = `<div class="custom-control custom-switch">
                                                            <input type="checkbox" data-custom-cost-id="${id}" class="custom-control-input custom-cost-toggle-button" name="status_${id}" id="status_${id}" ${status ? 'checked' : ''}>
                                                            <label style="padding-top: 3px" class="custom-control-label text-xs font-medium" for="status_${id}">${status_text}</label>
                                                        </div>`

                        return customCostStatusBtnsHTML;
                    }
                  },
                  {
                      "targets": 1,
                      "searchable": false,
                      "orderable": true,
                      "data": "name",
                      "className": "tbl--strong",
                      render: function ( data, type, row ) {
                          return `<div class="text-sm font-medium">${data}</div>`
                      }
                  },
                  {
                      "targets": 2,
                      "searchable": false,
                      "orderable": false,
                      "data": 'custom_cost_id',
                      "render": function ( data, type, row, meta ) {
                          var customCostId = row.custom_cost_id
                          var customCostName = encodeURI(row.name)
                          var customCostBtnsHTML = `<div class="flex">
                                                        <a class="action-btn--on-tr-hover inline-block align-middle tailwind-px-2 text-gray-30 hover:text-gray-800" href="/cost/edit-custom-cost?cost_id=${customCostId}" data-turbo-frame="custom_cost_modal">
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.5118 1.48816L13.0421 0.957825V0.957825L12.5118 1.48816ZM3.33333 13.0237V13.7737C3.53225 13.7737 3.72301 13.6946 3.86366 13.554L3.33333 13.0237ZM1 13.0237H0.25C0.25 13.4379 0.585786 13.7737 1 13.7737L1 13.0237ZM1 10.6429L0.46967 10.1126C0.329018 10.2533 0.25 10.444 0.25 10.6429H1ZM10.6851 2.01849C11.0431 1.6605 11.6235 1.6605 11.9815 2.01849L13.0421 0.957825C12.0984 0.0140582 10.5682 0.0140582 9.62445 0.957825L10.6851 2.01849ZM11.9815 2.01849C12.3395 2.37647 12.3395 2.95687 11.9815 3.31485L13.0421 4.37551C13.9859 3.43174 13.9859 1.90159 13.0421 0.957825L11.9815 2.01849ZM11.9815 3.31485L2.803 12.4933L3.86366 13.554L13.0421 4.37551L11.9815 3.31485ZM3.33333 12.2737H1V13.7737H3.33333V12.2737ZM9.62445 0.957825L0.46967 10.1126L1.53033 11.1733L10.6851 2.01849L9.62445 0.957825ZM0.25 10.6429V13.0237H1.75V10.6429H0.25ZM8.62445 3.01849L10.9815 5.37551L12.0421 4.31485L9.68511 1.95783L8.62445 3.01849Z" fill="currentColor"/>
                                                            </svg>
                                                          </a>
                                                          <a class="action-btn--on-tr-hover inline-block align-middle tailwind-px-2 text-gray-30 hover:text-gray-800" href="/cost/show-delete-modal?cost_id=${customCostId}&cost_name=${customCostName}" data-turbo-frame="custom_cost_modal">
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.6666 3.66667L11.0884 11.7617C11.0386 12.4594 10.458 13 9.75847 13H4.24145C3.54193 13 2.96135 12.4594 2.91151 11.7617L2.33329 3.66667M5.66663 6.33333V10.3333M8.33329 6.33333V10.3333M8.99996 3.66667V1.66667C8.99996 1.29848 8.70148 1 8.33329 1H5.66663C5.29844 1 4.99996 1.29848 4.99996 1.66667V3.66667M1.66663 3.66667H12.3333" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                          </a>
                                                    </div>`

                          return customCostBtnsHTML;
                      }
                  },
                  {
                      "targets": 3,
                      "searchable": false,
                      "orderable": true,
                      "data": "cost",
                      "className": "tbl--right"
                  },
                  {
                      "targets": 4,
                      "searchable": false,
                      "orderable": true,
                      "data": "category"
                  },
                  {
                      "targets": 5,
                      "searchable": false,
                      "orderable": true,
                      "data": "frequency"
                  },
                  {
                      "targets": 6,
                      "searchable": false,
                      "orderable": true,
                      "data": "start_date"
                  },
                  {
                      "targets": 7,
                      "searchable": false,
                      "orderable": true,
                      "data": "end_date"
                  },
              ],

              "order": [[0, "desc"]],
              "responsive": true,
              "dom": "ltip",
              "lengthMenu": [10, 25, 50, 100],
              "pagingType": "simple_numbers",
              "bStateSave": false,
              "searching": true,
              "bLengthChange": true,
              "processing": true,
              "serverSide": true,
              "ajax": {
                  "url": '/cost/custom-cost-table-data'
              },
              // Provide ID in TR tag so that we can do update/delete dynamically in the UI
              "createdRow": function( row, data, dataIndex ) {
                  $(row).addClass("hover:bg-gray-3")
                  $(row).attr('id', `custom_cost_${data.custom_cost_id}`);
              },
              initComplete: function() {
                  $('.dataTables_info, .dataTables_paginate, .dataTables_length').appendTo($('.tailwind-datatable-details'));
              },
              preDrawCallback: function() {
                  document.querySelector('table#custom-costs-table tbody').innerHTML = skeletonTableBodyHTML;
              }
          }
      );

      function delay(fn, ms) {
          let timer = 0
          return function(...args) {
              clearTimeout(timer)
              timer = setTimeout(fn.bind(this, ...args), ms || 0)
          }
      }

      $('#custom_costs_table_search').val(customCostsTable.search());

      // Make Search API work, because the (custom) searchbar is outside the datatable
      $(document).on("keypress keyup", '#custom_costs_table_search', delay(function () {
          customCostsTable.search($(this).val()).draw();
      }, 300));
      
    if ( $(dataTableElement).attr("data-shopifyTrackingExpenses") == "false" ) customCostsTable.column(0).visible(false)
    else customCostsTable.column(0).visible(true)
  }
}

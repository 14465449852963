import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dismissButton"]

  connect() {
    if (this.hasDismissButtonTarget) {
      this.dismissButtonTargets.forEach((dismissButton) => {
        dismissButton.addEventListener('click', () => this.dismiss());
      })
    }
    this.checkDismissedState();
  }

  dismiss() {
    this.element.remove();
    localStorage.setItem(this.storageKey(), "true");
  }

  checkDismissedState() {
    if (localStorage.getItem(this.storageKey()) === "true") {
      this.element.remove();
    } else {
      this.element.classList.remove('hidden');
    }
  }

  storageKey() {
    return `DISMISSED-${this.element.id}`;
  }
}
